import slideToggle from './sliding.js';

const trigger = document.querySelector('.js-mobile-toggle');
const mobileMenu = document.querySelector('.mobile-menu');

if (trigger) {
    trigger.addEventListener('click', function(e) {
        e.preventDefault();
        slideToggle(mobileMenu);
    });
}
