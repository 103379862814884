var calculator = document.querySelector("#calculator");
if (calculator) {
  var calc = new Vue({
    el: calculator,

    data: {
      applicants: 1,
      spouse: 0,
      children0To11: 0,
      children12To17: 0,
      children18To25: 0,
      parents: 0,

      ownershipType: "full", // can be 'half'
    },

    computed: {
      ownershipFee: function () {
        if (this.ownershipType == "full") {
          return 500000;
        } else if (this.ownershipType == "half") {
          return 350000;
        }
      },
      dependentsOverFour: function () {
        var sum =
          this.getNumber([
            this.spouse,
            this.children0To11,
            this.children12To17,
            this.children18To25,
            this.parents,
          ]) - 4;
        if (sum > 0) {
          return sum;
        } else {
          return 0;
        }
      },

      totalApplicationFee: function () {
        var sum = this.getNumber([
          this.children0To11,
          this.children12To17,
          this.children18To25,
          this.parents,
        ]);

        return sum * 1500;
      },

      totalProcessingFee: function () {
        var youngerGroup = this.getNumber([
          this.children0To11,
          this.children12To17,
        ]);
        var lowerTier = youngerGroup * 500;

        var olderGroup = this.getNumber([this.parents, this.children18To25]);
        var higherTier = olderGroup * 1500;

        return lowerTier + higherTier;
      },

      dueDiligenceFee: function () {
        var youngerGroup = this.getNumber([this.children12To17]);
        var lowerTier = youngerGroup * 2000;

        var olderGroup = this.getNumber([this.parents, this.children18To25]);
        var higherTier = olderGroup * 5000;

        return lowerTier + higherTier;
      },

      localAgentFee: function () {
        var sum = this.getNumber([
          this.children0To11,
          this.children12To17,
          this.children18To25,
          this.parents,
        ]);

        return sum * 1000;
      },

      governmentFee: function () {
        var sum = this.getNumber([this.dependentsOverFour]);

        return sum * 25000;
      },

      passportFee: function () {
        var sum = this.getNumber([
          this.children0To11,
          this.children12To17,
          this.children18To25,
          this.parents,
        ]);

        return sum * 270;
      },

      vatFee: function () {
        var sum = this.getNumber([
          this.children0To11,
          this.children12To17,
          this.children18To25,
          this.parents,
        ]);
        var agentFee = sum * 1000;

        return this.localAgentFee * 0.15;
      },

      costOfApplication: function () {
        var sum = this.getNumber([
          this.ownershipFee,
          this.vatFee,
          this.passportFee,
          this.governmentFee,
          this.localAgentFee,
          this.dueDiligenceFee,
          this.totalProcessingFee,
          this.totalApplicationFee,
        ]);

        return sum;
      },
      bankFees: function () {
        return (this.costOfApplication - this.ownershipFee) * 0.01 * 1.15;
      },

      grandTotal: function () {
        return this.costOfApplication + this.bankFees;
      },
    },
    methods: {
      getNumber: function (array) {
        var total = 0;

        for (var i = 0, len = array.length; i < len; i++) {
          total += parseInt(array[i]);
        }

        return total;
      },

      toCurrency: function (value) {
        if (isNaN(value)) {
          return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            currencyDisplay: "code",
            minimumFractionDigits: 0,
          }).format(0);
        }

        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          currencyDisplay: "symbol",
          minimumFractionDigits: 0,
        }).format(value);
      },

      onlyNumbersPlease: function (e) {
        // If the user tries to enter letters or the negative sign
        if ((e.which >= 65 && e.which <= 90) || e.which == 173) {
          e.preventDefault();
        }
      },
      resetForm: function (e) {
        e.preventDefault();
        this.applicants = 1;
        this.spouse = 0;
        this.children0To11 = 0;
        this.children12To17 = 0;
        this.children18To25 = 0;
        this.parents = 0;
      },
    },
  });
}

var styleSelect = require("styleselect");

var select = document.querySelectorAll(".select");

[].forEach.call(select, function (el) {
  styleSelect(el);
});
